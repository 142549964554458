import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/cancel.svg'
import _imports_1 from '../assets/send.svg'
import _imports_2 from '../assets/plus.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "d-flex justify-space-between" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { cols: "9 pl-7" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.newGoalName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newGoalName) = $event)),
            density: "compact",
            label: "Введите название цели",
            variant: "underlined",
            onKeydown: _withKeys(_ctx.onGoalEvent, ["enter"])
          }, null, 8, ["modelValue", "onKeydown"])
        ]),
        _: 1
      }),
      (_ctx.newGoalName)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 0,
            class: "d-flex justify-end px-7"
          }, {
            default: _withCtx(() => [
              (_ctx.hasGoal)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    color: "grey",
                    variant: "tonal",
                    icon: "",
                    size: "small",
                    class: "mr-2",
                    onClick: _ctx.cancelGoal
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_img, {
                        width: "15",
                        height: "15",
                        src: _imports_0
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_btn, {
                color: "#1bd01d",
                variant: "tonal",
                icon: "",
                size: "small",
                onClick: _ctx.onGoalEvent
              }, {
                default: _withCtx(() => [
                  (_ctx.hasGoal)
                    ? (_openBlock(), _createBlock(_component_v_img, {
                        key: 0,
                        width: "15",
                        height: "15",
                        src: _imports_1
                      }))
                    : (_openBlock(), _createBlock(_component_v_img, {
                        key: 1,
                        width: "15",
                        height: "15",
                        src: _imports_2
                      }))
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}