
import { defineComponent, PropType } from 'vue';

import GoalField from '@/components/GoalField.vue';

import { IGoal } from '../api/types/types';

export default defineComponent({
  name: 'GoalsList',
  components: {
    GoalField,
  },
  props: {
    goals: {
      type: Array as PropType<IGoal[]>,
      required: true,
    },
    ownerName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const editGoal = (id: string) => emit('goal:edit', id);
    const deleteGoal = (id: string) => emit('goal:delete', id);
    const changeGoal = (data: { goalId: string, name: string }) => emit('goal:change', data);
    const cancelGoal = (id: string) => emit('goal:cancel', id);
    return {
      editGoal,
      deleteGoal,
      changeGoal,
      cancelGoal,
    };
  },
});
