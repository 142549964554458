const DEFAULT_PATH = 'https://habical.marcuzy.workers.dev/api';

async function getRequest(url: string) {
  const response = await fetch(`${DEFAULT_PATH}/${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.json();
}

async function postRequest(url: string, data: object) {
  const response = await fetch(`${DEFAULT_PATH}/${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return response.json();
}

async function putRequest(url: string, data: object) {
  const response = await fetch(`${DEFAULT_PATH}/${url}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return response.json();
}

async function deleteRequest(url: string) {
  const response = await fetch(`${DEFAULT_PATH}/${url}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.json();
}

export {
  getRequest, postRequest, putRequest, deleteRequest,
};
