
import {
  defineComponent, computed, ref, PropType,
} from 'vue';

import { IGoal } from '../api/types/types';

export default defineComponent({
  name: 'ReportField',
  props: {
    goals: {
      type: Array as PropType<IGoal[]>,
      required: true,
    },
    date: {
      type: Number,
      default: 0,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    goalId: {
      type: String as PropType<IGoal['id']>,
      default: '',
    },
    selectedReportId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const selectedGoalId = ref<string>(props.goalId);
    const selectedDate = ref<string>('');
    if (props.date) {
      const preparedDate = new Date(props.date).toLocaleDateString()
        .replaceAll('/', '-')
        .split('-')
        .reverse()
        .join('-');

      selectedDate.value = preparedDate;
    }
    const isCompleted = ref<boolean>(props.completed);

    const hasReport = computed(() => props.selectedReportId);

    const addReport = () => {
      const data = {
        goalId: selectedGoalId.value,
        date: +new Date(selectedDate.value),
        completed: isCompleted.value,
      };

      if (hasReport.value) {
        emit('report:change', {
          ...data,
          reportId: props.selectedReportId,
        });
      } else {
        emit('report:add', data);
        selectedGoalId.value = '';
        selectedDate.value = '';
        isCompleted.value = false;
      }
    };

    return {
      selectedGoalId,
      selectedDate,
      isCompleted,
      addReport,
    };
  },
});
