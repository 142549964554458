
import {
  defineComponent, PropType, watch, toRefs, ref,
} from 'vue';

interface IDate {
  date: number;
  status: string;
}
// Todo: сделать поправку на високосный год
const MONTH_LENGTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const DAY_OFFSET = ['six', 'zero', 'one', 'two', 'three', 'four', 'five'];
const WEEK_DAYS = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export default defineComponent({
  name: 'MarkedCalendar',
  props: {
    monthNumber: {
      type: Number,
      required: true,
    },
    dates: {
      type: Array as PropType<IDate[]>,
      required: true,
    },
  },
  setup(props) {
    const currentMonthLength = MONTH_LENGTH[props.monthNumber - 1];
    const currentMonth = ref<any[]>([]);

    const firstDay = new Date().setDate(1);
    const dayOfWeek = new Date(firstDay).getDay();
    const dayOffset = DAY_OFFSET[dayOfWeek];
    const weekDays = WEEK_DAYS;

    const getStatusDate = (dates: IDate[]) => {
      currentMonth.value = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < currentMonthLength; i++) {
        const dayObject = {
          dayNumber: i + 1,
          dayStatus: dates.map((d) => {
            const a = new Date(d.date).getDate();

            return i + 1 === a ? d.status : '';
          }).filter((status) => status),
        };
        currentMonth.value.push(dayObject);
      }
    };

    const { dates } = toRefs(props);

    watch(dates, (newValue) => {
      getStatusDate(newValue);
    });

    return {
      currentMonthLength,
      currentMonth,
      dayOffset,
      weekDays,
      getStatusDate,
    };
  },
});
