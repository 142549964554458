
import {
  defineComponent, onMounted, ref, computed, watch,
} from 'vue';

import GoalsList from '@/components/GoalsList.vue';
import GoalField from '@/components/GoalField.vue';
import ReportField from '@/components/ReportField.vue';
import EditReport from '@/components/EditReport.vue';
import MarkedCalendar from '@/components/MarkedCalendar.vue';

import {
  getRequest, postRequest, deleteRequest, putRequest,
} from './api/requests';
import { IMember, IGoal, IReport } from './api/types/types';

export default defineComponent({
  name: 'App',
  components: {
    GoalsList,
    GoalField,
    ReportField,
    EditReport,
    MarkedCalendar,
  },
  setup() {
    // data
    const members = ref<IMember[]>([]);
    const goals = ref<IGoal[]>([]);
    const reports = ref<IReport[]>([]);

    const selectedMemberId = ref<string>('4');
    const selectedGoalId = ref<string>('');

    // computed
    // eslint-disable-next-line max-len
    const selectedOwner = computed(() => members.value?.find(({ id }) => id === selectedMemberId.value)?.name) ?? 'Оксана';
    const memberGoals = computed(() => goals.value
      .filter((goal) => goal)
      .filter(({ memberId }) => memberId === selectedMemberId.value));
    const memberReports = computed(() => reports.value
      .filter((report) => report)
      .filter(({ memberId }) => memberId === selectedMemberId.value));
    const selectedReports = computed(() => memberReports.value
      .filter(({ goalId }) => goalId === selectedGoalId.value));
    const preparedReports = computed(() => selectedReports.value
      .map(({ date, completed }) => ({
        date,
        status: completed ? 'success' : 'error',
      })));
    const currentMonthNumber = computed(() => {
      const today = new Date();

      return today.getMonth() + 1;
    });

    // api methods
    const getMembers = async () => {
      const { list } = await getRequest('members');
      members.value = list;
    };
    const getGoals = async () => {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();

      const { list } = await getRequest(`goals?month=${currentMonth}&year=${currentYear}`);
      goals.value = list.map((goal: IGoal) => ({
        ...goal,
        isEdit: false,
      }));
    };

    const addGoal = async (newGoalName: string) => {
      try {
        const data = {
          memberId: selectedMemberId.value,
          name: newGoalName,
          ownerName: selectedOwner.value,
          date: +new Date(),
        };

        const addedGoal = await postRequest('goals', data);
        goals.value.push(addedGoal);
      } catch (e) {
        console.log(e);
      }
    };

    const deleteGoal = async (goalId: string) => {
      await deleteRequest(`goals/${goalId}`);
      const index = goals.value.findIndex(({ id }) => id === goalId);
      goals.value.splice(index, 1);
    };

    const editGoal = (goalId: string) => {
      const goal = goals.value.find(({ id }) => id === goalId)!;
      const index = goals.value.findIndex(({ id }) => id === goalId);

      goals.value.splice(index, 1, {
        ...goal,
        isEdit: true,
      });
    };

    const changeGoal = async (data: { goalId: string, name: string }) => {
      const { goalId, name } = data;

      await putRequest(`goals/${goalId}`, { name });

      const goal = goals.value.find(({ id }) => id === goalId)!;
      const index = goals.value.findIndex(({ id }) => id === goalId);

      goals.value.splice(index, 1, {
        ...goal,
        isEdit: false,
        name,
      });
    };

    const cancelGoal = (goalId: string) => {
      const goal = goals.value.find(({ id }) => id === goalId)!;
      const index = goals.value.findIndex(({ id }) => id === goalId);

      goals.value.splice(index, 1, {
        ...goal,
        isEdit: false,
      });
    };

    const getReports = async () => {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();

      const { list } = await getRequest(`reports?month=${currentMonth}&year=${currentYear}`);
      const monthList = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ];
      reports.value = list.map((report: IReport) => {
        const date = new Date(report.date);
        const day = date.getDate();
        const month = monthList[date.getMonth()];
        const year = date.getFullYear();

        return {
          ...report,
          name: `Отчет за ${day}.${month}.${year}`,
          isEdit: false,
        };
      });
    };

    const addReport = async (data: { goalId: string, date: number, completed: boolean }) => {
      const addedReport = await postRequest('reports', {
        ...data,
        memberId: selectedMemberId.value,
      });

      reports.value.push(addedReport);
    };

    const deleteReport = async (reportId: string) => {
      await deleteRequest(`reports/${reportId}`);

      const index = reports.value.findIndex(({ id }) => id === reportId);
      reports.value.splice(index, 1);
    };

    const editReport = (reportId: string) => {
      const report = reports.value.find(({ id }) => id === reportId)!;
      const index = reports.value.findIndex(({ id }) => id === reportId);

      reports.value.splice(index, 1, {
        ...report,
        isEdit: true,
      });
    };

    // eslint-disable-next-line max-len
    const changeReport = async (data: { goalId: string, date: number, completed: boolean, reportId: string }) => {
      const {
        reportId, goalId, date, completed,
      } = data;

      await putRequest(`reports/${reportId}`, data);

      const report = reports.value.find(({ id }) => id === reportId)!;
      const index = reports.value.findIndex(({ id }) => id === reportId);

      reports.value.splice(index, 1, {
        ...report,
        goalId,
        date,
        completed,
        isEdit: true,
      });
    };

    onMounted(async () => {
      await getMembers();
      await getGoals();
      await getReports();
    });

    watch((selectedMemberId), () => {
      selectedGoalId.value = '';
    });

    return {
      members,
      goals,
      reports,
      memberGoals,
      memberReports,
      selectedOwner,
      selectedGoalId,
      selectedMemberId,
      selectedReports,
      preparedReports,
      currentMonthNumber,
      editGoal,
      addGoal,
      deleteGoal,
      changeGoal,
      cancelGoal,
      addReport,
      deleteReport,
      editReport,
      changeReport,
    };
  },
});
