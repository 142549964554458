
import {
  defineComponent, PropType, ref, computed, toRefs,
} from 'vue';
import ReportField from '@/components/ReportField.vue';
import { IGoal, IReport } from '../api/types/types';

export default defineComponent({
  name: 'EditReport',
  components: {
    ReportField,
  },
  props: {
    reports: {
      type: Array as PropType<IReport[]>,
      required: true,
    },
    goals: {
      type: Array as PropType<IGoal[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedGoalId = ref<string>('');
    const selectedReportId = ref<string>('');

    const goalReports = computed(() => props.reports
      .filter(({ goalId }) => goalId === selectedGoalId.value));
    const selectedReport = computed(() => goalReports.value
      .find(({ id }) => id === selectedReportId.value) ?? { isEdit: false });

    const deleteReport = () => {
      if (selectedReportId.value) {
        emit('report:delete', selectedReportId.value);
        selectedReportId.value = '';
      }
    };

    const editReport = () => {
      if (selectedReportId.value) {
        emit('report:edit', selectedReportId.value);
      }
    };

    const changeReport = (data: any) => {
      emit('report:change', data);
    };

    return {
      selectedGoalId,
      selectedReportId,
      selectedReport,
      goalReports,
      deleteReport,
      editReport,
      changeReport,
    };
  },
});
