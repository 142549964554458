import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/send.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { cols: "10 pl-7" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "d-flex align-end" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.selectedGoalId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedGoalId) = $event)),
                    items: _ctx.goals,
                    "item-title": "name",
                    "item-value": "id",
                    label: "Список целей",
                    variant: "underlined"
                  }, null, 8, ["modelValue", "items"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.selectedDate,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDate) = $event)),
                    type: "date",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_checkbox, {
                    modelValue: _ctx.isCompleted,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isCompleted) = $event)),
                    density: "compact",
                    label: "Цель выполнена"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, { class: "d-flex justify-end px-7" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            color: "#1bd01d",
            variant: "tonal",
            icon: "",
            size: "small",
            onClick: _ctx.addReport
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                width: "15",
                height: "15",
                src: _imports_0
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}