import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a0b843a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "marked-calendar d-flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekDays, (weekDay) => {
      return (_openBlock(), _createElementBlock("div", {
        key: weekDay,
        class: "day d-flex align-center justify-center"
      }, _toDisplayString(weekDay), 1))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentMonth, (day, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: day.dayNumber,
        class: _normalizeClass(["day d-flex align-center justify-center border", [
        index === 0 ? `offset-${_ctx.dayOffset}` : '',
        day.dayStatus,
      ]])
      }, _toDisplayString(day.dayNumber), 3))
    }), 128))
  ]))
}