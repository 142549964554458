
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  name: 'GoalField',
  props: {
    goalId: {
      type: String,
      default: '',
    },
    goalName: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const newGoalName = ref<string>(props.goalName);

    const hasGoal = computed(() => Boolean(props.goalId));

    const onGoalEvent = () => {
      if (hasGoal.value) {
        emit('goal:change', {
          goalId: props.goalId,
          name: newGoalName.value,
        });
      } else {
        emit('goal:add', newGoalName.value);
      }

      newGoalName.value = '';
    };

    const cancelGoal = () => {
      emit('goal:cancel', props.goalId);
    };

    return {
      newGoalName,
      hasGoal,
      cancelGoal,
      onGoalEvent,
    };
  },
});
